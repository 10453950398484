<template>
    <table class="table table-hover table-vcenter table-bordered dataTable block-list" style="width:100%">
        <thead>
            <tr>
                <th>ID</th>
                <th>URL</th>
                <th>Type</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody></tbody>
    </table>
</template>

<script>
    export default {
        props: {
            ajaxUrl: String,
        },

        mounted() {
            window.jQuery(this.$el).dataTable({
                processing: true,
                serverSide: true,
                ajax: {
                    url: this.ajaxUrl,
                },
                order: [0, "asc"],
                pageLength: 25,
                columns: [
                    {
                        data: 'id',
                    },
                    {
                        data: 'url',
                    },
                    {
                        data: 'type',
                    },
                    {
                        data: 'actions',
                        orderable: false,
                    },
                ],
            });
        }
    }
</script>
<style scoped>
    td:last-child  {
        text-align: center;
        width: 10%;
    }
</style>
