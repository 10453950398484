<template>
    <table class="table table-hover table-vcenter table-bordered dataTable block-list">
        <thead>
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Last Login</th>
                <th>Active</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody></tbody>
    </table>
</template>

<script>
    export default {
        props: {
            ajaxUrl: String,
        },

        mounted() {
            window.jQuery(this.$el).dataTable({
                processing: true,
                serverSide: true,
                ajax: {
                    url: this.ajaxUrl,
                },
                order: [0, "asc"],
                pageLength: 25,
                columns: [
                    {
                        data: 'id',
                    },
                    {
                        data: 'name',
                    },
                    {
                        data: 'email',
                    },
                    {
                        data: 'last_login',
                    },
                    {
                        data: 'active',
                        render: function (data)
                        {
                            return data ? '<i class="fas fa-check"></i>' : '<i class="fas fa-times"></i>';
                        }
                    },
                    {
                        data: 'actions',
                        orderable: false,
                    },
                ],
            });
        }
    }
</script>
<style scoped>
    td:last-child  {
        text-align: center;
        width: 10%;
    }
</style>
