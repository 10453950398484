<template>
    <table class="table table-vcenter table-sm table-bordered">
        <thead>
            <tr>
                <th>Bidder</th>
                <th>Options</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(bidder, idx) in bidderOptions" v-bind:key="bidder.bidder.prebid_name">
                <td>
                    <div class="form-check form-switch form-check-inline">
                        <input type="hidden" :value="bidder.enabled ? 1 : 0" :name="`enabled_bidders[${bidder.bidder.id}]`" :disabled="! canBidderBeEnabled(idx)">
                        <input class="form-check-input" type="checkbox" value="1" v-model="bidder.enabled" :disabled="! canBidderBeEnabled(idx)">
                        <label class="form-check-label">{{ bidder.bidder.name }}</label>
                    </div>
                </td>
                <td>
                    <div class="bidder-opts rounded-3 p-2 bg-light" v-for="(set, key) in bidder.option_sets" v-bind:key="key">
                        <div v-for="(value, field) in set" v-bind:key="field">
                            <label class="form-label" :for="field">{{ field }}</label>
                            <input v-model="set[field]" type="text" class="form-control" :id="field" :name="`bidder_options[${bidder.bidder.id}][${key}][${field}]`">
                        </div>

                        <div class="text-end">
                            <button type="button" class="btn btn-danger mt-2" @click="removeSet(idx, key)">Delete</button>
                        </div>
                    </div>

                    <div class="text-end">
                        <button type="button" class="btn btn-primary mt-2" @click="addSet(idx)">+ More params</button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style scoped>
.bidder-opts:not(:first-of-type) {
    margin-top: 1rem;
}
</style>

<script>
    export default {
        props: {
            initialBidderOptions: {
                type: Array,
                required: true
            },
            inventory: {
                type: Object,
                required: true
            },
        },

        data: function() {
            return {
                bidderOptions: this.initialBidderOptions,
            };
        },

        methods: {
            removeSet(idx, key) {
                this.bidderOptions[idx].option_sets.splice(key, 1);

                if (this.bidderOptions[idx].option_sets.length === 0) {
                    this.bidderOptions[idx].enabled = false;
                }
            },
            addSet(idx) {
                const newSet = Object.assign({
                    labels: '',
                }, this.bidderOptions[idx].options);

                this.bidderOptions[idx].option_sets.push(newSet);
                this.bidderOptions[idx].enabled = true;
            },
            canBidderBeEnabled(idx) {
                return this.bidderOptions[idx]?.option_sets?.length !== 0;
            },
        },
    }
</script>
