<template>
    <div>
        <select
            :name="name"
            class="form-control"
            :class="{ 'is-invalid': isInvalid }"
            ref="picker"
            style="width: 100%;"
            :id="id"
            multiple
        >
            <option v-for="(option, id) in allValues" :value="id" :selected="mappedDefaultValues.includes(id)">{{ option }}</option>
        </select>
    </div>
</template>

<script>

    export default {
        props: {
            placeholder: {
                type: String,
            //    default: 'Please select',
            },
            name: {
                type: String,
                required: true,
            },
            id: {
                type: String,
            },
            // Everything we've checked
            defaultValues: {
                type: Array,
                default() {
                    return [];
                },
            },
            // Every single partner
            allValues: {
                type: Object,
                default() {
                    return {};
                },
            },
            isInvalid: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                picker: null,
                mappedDefaultValues: [],
            };
        },

        beforeMount() {
            this.mappedDefaultValues = this.defaultValues.map((value) => {
                return typeof value === 'string' ? value : `${value}`;
            });
        },

        mounted() {
            this.picker = window
                .jQuery(this.$refs.picker)
                .select2({
                    placeholder: this.placeholder,
                    multiple: true,
                });

            this.picker.on("change", e => {
                this.$emit("input", $(this.$refs.picker).select2("val"));
            });
        },
    };

</script>
