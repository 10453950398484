<template>
    <table class="table table-hover table-vcenter table-bordered dataTable">
        <thead>
            <tr>
                <th>ID</th>
                <th>Url</th>
                <th>Upload Date</th>
            </tr>
        </thead>
        <tbody></tbody>
    </table>
</template>

<script>
    export default {
        props: {
            ajaxUrl: String,
        },

        mounted() {
            window.jQuery(this.$el).dataTable({
                processing: true,
                serverSide: true,
                ajax: {
                    url: this.ajaxUrl,
                },
                order: [0, "desc"],
                pageLength: 25,
                columns: [
                    {
                        data: 'id',
                    },
                    {
                        data: 'url',
                        render: function (data)
                        {
                            return `<a href="${data}">${data}</a>`;
                        }
                    },
                    {
                        data: 'created_at',
                    }
                ],
            });
        }
    }
</script>
