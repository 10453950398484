<template>
    <table class="table table-bordered table v-center">
        <thead>
            <tr>
                <th>Key</th>
                <th>Value</th>
                <th>
                    <button type="button" class="btn btn-sm btn-primary js-bs-tooltip-enabled" @click="addRow()">
                        <i class="fa fa-plus"></i>
                    </button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(option, index) in options">
                <td>
                    <input type="hidden" name="option_ids[]" v-model="option.id">
                    <input type="text" class="form-control" name="option_names[]" v-model="option.option_name"/>
                </td>
                <td><input type="text" class="form-control" name="defaults[]" v-model="option.default"/></td>
                <td>
                    <button type="button" class="btn btn-sm btn-alt-secondary js-bs-tooltip-enabled" @click="removeRow(index)">
                        <i class="fa fa-fw fa-times"></i>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>

    export default {
        props: {
            defaultOptions: {
                type: Array,
                default: {
                    option_name: [],
                    default: String,
                },
            },
        },

        data() {
            return {
                options: [],
            };
        },

        mounted() {
            this.options = this.defaultOptions;
        },

        methods: {
            addRow() {
                this.options.push(
                    {
                        option_name: '',
                        default: '',
                    }
                );
            },
            removeRow(index) {
                this.options.splice(index, 1);
            },
        },
    };

</script>
